import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import InputGroup from 'common/components/InputGroup';
import Input from 'common/components/Input';
import RadioGroup from 'common/components/RadioGroup';
import SectionWrapper, {
  ContentArea,
  Heading,
  ButtonGroup,
  DonationForm,
  DonateButton
} from './donateSection.style';

import heartImage from 'common/assets/image/charity/heart-alt.svg';

const DonateSection = ({ row, col }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        paymentPolicy {
          id
          title
          text
          value
        }
        currencyOptions {
          id
          title
          value
        }
      }
    }
  `);

  const contactInput = {
    border: "1px solid #eeeeee",
    borderRadius: "0px",
    borderBottom: "3px solid #cac8c8",
    height: "43px",
    padding: "7px 15px",
    boxShadow: "none",
    fontSize: "14px",
    fontWeight: "500",
    borderRadius: "0px",
    backgroundClip: "inherit !important",
    marginBottom: "15px",
  };
  
  const [state, setState] = useState({
    name: '',
    email: '',
    contactNumber: '',
    message: ''
  });

  const handleFormData = (value, name) => {
    console.log('value',value)
    console.log('name', name)
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleDonation = (e) => {
    e.preventDefault();
    console.log('Donation form data: ', state);

    setState({
      ...state
    });
  };

    const iframeSource = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2437.827107091552!2d-0.17999262232610272!3d52.33728254974018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877dd38e202253b%3A0xa99f5412fdfa6c7b!2s25%20Wellsfield%2C%20Huntingdon%20PE29%201LW!5e0!3m2!1sen!2suk!4v1682369441871!5m2!1sen!2suk" style="border:0; height:300px; width: 100%" allowfullscreen=""></iframe>'

  return (
    <SectionWrapper id="donate">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentArea>
              <Heading>
              CONTACT US
              </Heading>
              <Text content=" &#64; &nbsp;&nbsp;25 Wellsfield, Huntingdon, Cambridgeshire, PE29 1LW " />
              {/* <Text content=" &#9990; &nbsp;&nbsp;0345 548 1189 (Office)" /> */}
              <Text content=" &#9990; &nbsp;&nbsp;0771 335 6759" />
              <Text content=" &#9993; &nbsp;&nbsp;contact@pnbcareservices.co.uk" />
            </ContentArea>
            <div style={{height:'300px', width: '100%', marginTop: '20px'}} dangerouslySetInnerHTML={{__html: iframeSource}}></div>
          </Box>

          <Box className="col" {...col}>
            <DonationForm onSubmit={(e) => handleDonation(e)}>
                <Input
                  id={"name"}
                  inputType="text"
                  placeholder="Name"
                  aria-label="name"
                  name="name"
                  className="contact-input"
                  style={contactInput}
                  required={true}
                  onChange={(e) => handleFormData(e, 'name')}            
                />
              
              <Input
                id={"email"}
                inputType="email"
                placeholder="Email"
                aria-label="email"
                name="email"
                style={contactInput}
                required={true}
                onChange={(e) => handleFormData(e, 'email')}            
              />
              <Input
                id={"contactNumber"}
                inputType="number"
                placeholder="Contact Number"
                aria-label="contactNumber"
                name="contactNumber"
                style={contactInput}
                required={true}
                onChange={(e) => handleFormData(e, 'contactNumber')}            
              />
              <Input
                inputType="textarea"
                placeholder="Write Message"
                aria-label="message"
                name="message"
                style={contactInput}
                onChange={(e) => handleFormData(e, 'message')}
              />
              <DonateButton type="submit">
                Submit
              </DonateButton>
            </DonationForm>
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

// DonateSection style props
DonateSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textStyle: PropTypes.object,
};

// DonateSection default style
DonateSection.defaultProps = {
  // DonateSection row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // DonateSection col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default DonateSection;
