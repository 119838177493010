import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import NavbarWrapper, { MenuWrapper, Button } from './navbar.style';

import logoImage from 'common/assets/image/charity/logo.jpg';
import heartImage from 'common/assets/image/charity/heart-red.png';

const Navbar = () => {
  return (
    <NavbarWrapper className="navbar">
      <Container fullWidth={true}>
        <Link className="logo" to="/">
          <Image src={logoImage} alt="PNB Care Servcies" />
        </Link>
        <MenuWrapper>
          <AnchorLink className="smooth_scroll" href="#scrollhome" offset={81}>
            Home
          </AnchorLink>
          <AnchorLink className="smooth_scroll" href="#feature" offset={81}>
            Care Services
          </AnchorLink>
          <AnchorLink className="smooth_scroll" href="#donate" offset={81}>
            Contact Us
          </AnchorLink>
          <AnchorLink className="smooth_scroll" href="#donate" offset={81}>
            About Us
          </AnchorLink>
          <AnchorLink className="smooth_scroll" href="#donate" offset={81}>
            Recuirtment
          </AnchorLink>
          <Button>
            <span className="text">Apply Now</span>
            <Image src={heartImage} alt="Charity Landing" />
          </Button>
        </MenuWrapper>
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
